.box {
    border: 2px solid #0c0 !important;
    background-color: #fff;
    position: relative;
}

.box::before,
.box::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
}

.box::before {
    border-width: 1.3em;
    border-right-color: #0c0;
    border-top-color: #0c0;
}

.box::after {
    border-radius: 0.4em;
    border-right-color: #0c0;
    border-top-color: #0c0;
    font-size: 1.1em;
    transition: all .2s;
    font-family: "Material Icons";
    content: "\e877";
    color:#fff;
}