.tab .tab-style {
    cursor: pointer;
    border-bottom: 1px solid #eae5e5;
    border-right: 1px solid #eae5e5;
    text-align: center;
    padding: 10px !important;
}
.tab {
    background-color: white;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    border: 1px solid #efefef;
}
.active-tab {
    background: #00acc1;
}
.tab-header{
    color: black;
    font-weight: 500;
}
.active-tab-header{
    color: white;
}
.grid-header-title{
    float: left;
    margin: 10px 0px 0px 0px !important;
    font-weight: 500 !important;
}
.grid-header-button{
    float: right;
    padding: 3px 10px !important;
}
.grid-header-button-icon{
    margin: 0px !important;
}
.footer{
    text-align: center;
    border-top: 1px solid #cacaca !important;
}
.sidebar-logo{
width: 30px;
height: 30px;
border: 0; 
    vertical-align: middle;
}
/* .menu > li{
    color: red;
} */
.ag-overlay{
    top:45px !important;
    opacity: .8;
}
.maincontent{
    padding-top: 10px !important;
}
.grid_action_btn{
    border: 1px solid #e9e7e7;
    border-radius: 2px;
    margin-right: 2px;
    cursor: pointer;
    font-size: 20px !important;
}
.separator {
    display: flex;
    align-items: center;
    text-align: center;
    color: #bdc3c7;
}
.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #bdc3c7;
}
.separator::before {
    margin-right: .25em;
}
.separator::after {
    margin-left: .25em;
}
.wizard-selected-tab {
    color: #F44336 !important;
}

.wizard-tab {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    display: inline-block;
    padding: 15px;
    cursor: pointer;
    color: #919699;
}

.wizard-btn{
    margin: 10px !important;
}

.fa-question-circle:hover:after{
    background-color: bisque !important;
    border-radius: 2px !important;
}

.code-box-demo .ant-select {
    margin: 0 8px 10px 0;
}
  
.ant-row-rtl .code-box-demo .ant-select {
    margin: 0 0 10px 8px;
}

#components-select-demo-search-box .code-box-demo .ant-select {
    margin: 0;
}

.image {
    opacity: 1;
    display: block;
    width: 100% !important;
    height: 100% !important;
    transition: .5s ease;
    backface-visibility: hidden;
}

.image-overlay {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .thumbnail:hover .image {
    opacity: 0.3;
  }
  
  .thumbnail:hover .image-overlay{
    opacity: 1;
  }
  .overlay-close-btn {
    top: 16px;
  }

  .overlay-action-btn {
    width:50%
  }

  .action-icon {
    color: white;
    border-radius: 12px;
    background-color: #0000007a;
  }

  .close-btn-overlay {
    top: 10%;
    padding: 2px;
  }

  .close-btn {
    float: right;
    font-size: 18px !important;
  }

  .local-upload-icon{
    float:left;
  }

  .asset-upload-icon{
    float: right;    
  }

  .control-tooltip{
    font-size: 16px !important;
  }

  .checkbox-control {
    padding: 0px 10px 0px 2px  !important;
  }

  .grid-container-control .griditem-control {
    padding: 5px 10px !important;
  }

  .grid-container-control {
    padding: 15px 0px;
  }
  .product-option-radio-btn{
    padding: 10px !important;
  }
  .option-label{
    font-weight: 500 !important;
  }
  .product-page-back-btn{
    color: rgb(115, 108, 243) !important;
    cursor: pointer;
  }
  .pagination{
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    float: right;
  }
  .attribute-txtbox input{
    padding: 6px;
  }

  .attribute-txtbox fieldset:hover, .attribute-txtbox fieldset:active, .attribute-txtbox fieldset:focus{
    border: 1px solid rgb(115, 108, 243) !important;
  }

  .customise-select{
    width: 100%;
    border: 1px solid grey !important;
    border-bottom: 1px solid grey !important;
  }
  .custom-autocomplete-input-root {
    padding: 0px 65px 0px 10px !important;
  }
  .custom-autocomplete-tag{
    height:25px !important;
  }
  .custom-autocomplete-tag svg{
    height: 15px !important;
    width: 15px !important;
  }

  .ag-grid-textbox{
    height: 35px;
    border: 1px solid #8080804f;
    width: 100%;
  }

  .select-control-root {
    padding: 6px !important;
  }

  .tree-view-container .tree-view-root {
    padding: 15px !important;
  }

  .tree-view-label {
    color: grey;
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  .tree-view-ul {
    margin-left: 15px !important;
  }


  .partner-role{
  background: #00acc1;
  color: white;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 10px;
  }
  /* .tree-view-icon svg{
    color: grey;
  } */
  .wholesalor-hierarchy-title{
    float: left;
    margin-top: 15px !important;
    font-weight: 500 !important;
  }
  .hierarchy-tab-label-initial{
    font-weight: bold;
    font-size: 10px;
    margin-right: 5px;
    padding: 4px 7px;
    color: white;
    background-color: #00acc1;
    border-radius: 15px;
  }

  .product-label-color{
    background-color: #ffc107;
  } 

  .brand-label-color{
    background-color: #31988f;
  }

  .wholesalor-label-color{
    background-color: #00acc1;
  }

  .category-label-color {
    background-color: #9bcc73;
  }

  .pac-container{
    z-index: 10001;
  }
  /* .formio-dialog{
    z-index: 10000 !important;
  } */
  /* .MuiDialog-root{
    z-index: 10001 !important;
  } */

  .cls-asset-popup{
    z-index: 10001 !important;
  }

  .ag-theme-material .ag-cell.ag-cell-inline-editing {
    height: 46px !important;
    background-color: white !important;
  }

.google-places-autocomplete__input{
    border:1px solid #bdbcbc !important;
    height: 32px;
    border-radius: 5px;
    width: 100% !important;
  }
  .google-places-autocomplete__suggestion {
    font-size: 12px !important;
   
    padding: 7px !important;
    font-family: sans-serif !important;


  }

  .overlay-width{
    width: 20% !important;
  }

  .error-display {
    display: flex;
    align-items: center;
    width: 140px;
    padding: 3px;
    color: #d40707;
    background-color: #ffdde357;
    border-radius: 4px;
    font-size: 0.9rem;
  }

  .MuiCollapse-entered {
    padding-left: 30px !important;
  }

  .grid-container .page-grid-item {
    padding: 10px 15px !important;
  }

  .access-denied {
    cursor: not-allowed;
  }

  .yellow-ready {
background-color: #e8ee6a57 !important;
  }

  .green-success {
background-color: #08972c57 !important;
  }

  .red-error {
    background-color: #ed042f57 !important;
  }

  .aggrid-paging-panel {
    border-top: 1px solid #e2e2e2;
    color: rgba(0, 0, 0, 0.54);
    height: 56px;
    padding: 0 24px;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }

  .aggrid-paging-page-summary-panel{
    display: flex;
    align-items: center;
  }

  .aggrid-disable {
    pointer-events: none;
    cursor: none;
  }

  .aggrid-paging-button {
    margin: 0 8px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  .pagination-panel {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: normal;
  }

  .aggrid-disabled .aggrid-icon{
    color: rgba(0, 0, 0, 0.38);
    opacity: 0.38;
  }

  .aggrid-icon {
    width: 18px !important;
    height: 18px !important;
  }

  .search-box div:focus{
    transform: scaleX(1) !important;
  }

  .search-box input{
    font-size: 13px;
  }

  .search-component, .add-new-btn{
    display: inline;
    cursor:pointer;
  }

  .add-new-btn{
    float: right;
  }

  .aggrid-new-button{
    margin: 0px !important;
    padding: 5px 10px !important;
  }

  .search-component-icon {
    width: 0.75em !important;
    height: 0.75em !important;
  }

  .search-component-icons {
    margin-top: 8px;
    width: fit-content;
    display: inline-flex;
  }

  .label_alignment {
    text-align: left;
    font-weight: 500;
    margin-bottom: auto;
    margin-top: auto;
  }

  .margin-auto {
    margin: auto !important;
  }

  .griditem-padding { 
    padding: 10px 0px;
  }

